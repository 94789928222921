<template>
  <div>
    <div class="flix-form-group">
      {{ $t("applicants.description").split("{icon}")[0] }}
      <icon id="circle-remove" />{{
        $t("applicants.description").split("{icon}")[1]
      }}
      <icon id="circle-plus" />{{
        $t("applicants.description").split("{icon}")[2]
      }}
    </div>
    <div :key="$store.state.business.weekday">
      <div
        class="flix-form-group"
        style="background-color: #eee; padding: 40px"
      >
        <!-- <getWeekday :key="$store.state.business.weekday + 0" /> -->
        <div v-if="$store.state.tableau.view === 'day'" class="flix-form-group">
          <h2>
            {{
              $t("time.weekdaysSettings", {
                day: $t("dates.weekdays")[$store.state.business.weekday]
              })
            }}
          </h2>
        </div>
        <maxApplicants />
        <div
          class="flix-flex flix-flex-center flix-form-group"
          style="margin-bottom: 70px; gap: 30px; justify-content: flex-start"
        >
          <appointmentDuration />
          <appointmentNext />
          <appointmentBuffer />
        </div>
        <appointmentTableau :key="$store.state.tableau.updateKey" />
      </div>
      <div class="flix-form-group"></div>
      <!-- <pause />
      <appointmentCopy v-if="weekdayLength > 1" /> -->
    </div>

    <saveBtn v-if="checkData()" class="saveBtn" :callback="setCopy" />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  components: {
    // getWeekday() {
    //  return import("@/components/business/applicants/weekday");
    //  },
    maxApplicants() {
      return import("@/components/business/applicants/maxApplicants");
    },
    appointmentDuration() {
      return import("@/components/business/applicants/appointmentDuration");
    },
    appointmentNext() {
      return import("@/components/business/applicants/appointmentNext");
    },
    appointmentBuffer() {
      return import("@/components/business/applicants/buffer");
    },
    // appointmentCopy() {
    // return import("@/components/business/applicants/appointmentCopy");
    // },
    // pause() {
    // return import("@/components/business/time/pause");
    // },
    appointmentTableau() {
      return import("@/components/business/tableau");
    }
  },
  extends: def,
  data() {
    return {
      weekdayLength: Object.keys(
        this.$store.state.business.unsaved.include.weekdays
      ).length
    };
  },
  mounted() {
    if (
      typeof this.$store.state.business.weekday === "undefined" ||
      typeof this.$store.state.business.unsaved.include.weekdays[
        this.$store.state.business.weekday
      ] === "undefined"
    ) {
      this.$store.commit(
        "business/setWeekday",
        Object.keys(this.$store.state.business.unsaved.include.weekdays)[0]
      );
    }

    this.$store.commit("business/setCopy", {});
  },
  methods: {
    setCopy() {
      var copy = this.$store.state.business.weekday;
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));

      Object.keys(this.$store.state.business.copy).forEach(
        function(weekday) {
          data.include.weekdays[weekday][2] = data.include.weekdays[copy][2];
          data.max_applicants[weekday] = data.max_applicants[copy];
          data.next_appointments[weekday] = data.next_appointments[copy];
        }.bind(this)
      );

      this.$store.commit("business/prefetch", data);
      this.setSave();
    },
    nextStep() {
      this.$router.push({ name: "settings" });
    },
    checkData() {
      return true;
    }
  }
};
</script>
