<script>
export default {
  components: {},
  beforeDestroy() {
    var data = JSON.parse(JSON.stringify(this.$store.state.business.assistent));
    this.$store.commit("business/prefetch", data);
  },
  methods: {
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      this.$store.commit("business/update", data);

      if (
        typeof this.$store.state.business.assistent.ID === "undefined" ||
        !this.$store.state.business.assistent.ID
      ) {
        this.nextStep();
      } else {
        this.finalStep();
      }
    },
    finalStep() {
      this.$router.push("preview");
    },
    nextStep() {
      return false;
    },
    checkData() {
      return true;
    }
  }
};
</script>
<style lang="sass" scoped></style>
